import CryptoJS from 'crypto-js';
import md5 from 'js-md5'
import http from './http.js';
import constants from './constants';
import Element from "element-ui";
import router from "../../router";
// 默认的 KEY 与 iv 如果没有给,可自行设定，但必须16位
const KEY = CryptoJS.enc.Utf8.parse("wuningge12345678");
const IV = CryptoJS.enc.Utf8.parse('demojava81234567');
/**
 * AES加密 ：字符串 key iv  返回base64
 */
export function Encrypt(word, keyStr, ivStr) {
    if(word){
        let key = KEY;
        let iv = IV;
        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    }else{
        return '';
    }


}
/**
 1. AES 解密 ：字符串 key iv  返回base64
 2.  3. @return {string}
 */
export function Decrypt(word, keyStr, ivStr) {
    if(word) {
        let key = KEY;
        let iv = IV;
        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
            iv = CryptoJS.enc.Utf8.parse(ivStr);
        }
        let base64 = CryptoJS.enc.Base64.parse(word);
        let src = CryptoJS.enc.Base64.stringify(base64);
        let decrypt = CryptoJS.AES.decrypt(src, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }else{
        return '';
    }
}

export function EncryptMessage(token,entity) {
    if(token){
        let key = token.substring(0,16);
        let iv  = token.substring(1,17);
        return Encrypt(JSON.stringify(entity),key,iv);
    }else{
        return Encrypt(JSON.stringify(entity));
    }
}

export function DecryptMessage(token,entity) {
    if(token){
        return Decrypt(entity,token.substring(0,16),token.substring(1,17));
    }else{
        return Decrypt(entity);
    }
}

/**
   处理CHAT消息
 */
export function chatMessage(CHAT,message) {
    let oldToken=sessionStorage.getItem('AccessToken');
    if(message){
       let obj =JSON.parse(message);
       if(obj.name==constants.ORDER_S_UPDATE_TOKEN){
           sessionStorage.setItem('AccessToken',obj.data);
           //更新完token。发送已更新指令
           CHAT.send(constants.ORDER_R_UPDATED_TOKEN,'',oldToken);
       }else if(obj.name==constants.ORDER_TOKEN_OUT){ //TOKEN验证失败
           console.log('Token 验证失败');
           Element.Notification({
               title: '温馨提示',
               message: "Token已失效，请重新登录！",
               dangerouslyUseHTMLString: true,
               type: 'warning',////"success","warning"
               position: 'bottom-right',
               duration:2000
           });
           window.setTimeout(()=>{
               router.push({path: '/login', query: {}});
           },200)
       }else{
           let myEvent = new CustomEvent(obj.name,{
               "detail": obj.data,//可携带额外的数据
           });
           document.dispatchEvent(myEvent);
       }
    }

}

/**
 登录
 */
export function login(loginName,loginPwd) {
    let params={}
    params.entity={
        condition:{
            'account_name':{
                value:loginName,
                compareKey:'eq'
            },
            'login_pwd':{
                value:md5(loginPwd),
                compareKey:'eq'
            },
            'type':{
                value:0,
                compareKey:'eq'
            },
        },
    };
    return http({
        url: '/default/login',
        method: 'post',
        data:params
    })
}

/**
 设备登录
 */
export function equipmentLogin(type,equipmentCode) {
    let params={}
    params.entity={
        condition:{
            'type':{
                value:type,
                compareKey:'eq'
            },
            'equipmentCode':{
                value:equipmentCode,
                compareKey:'eq'
            }
        },
    };
    return http({
        url: '/default/login',
        method: 'post',
        data:params
    })
}




export function upload(url,formData,back) {
    return http({
        url: url,
        method: 'post',
        data: formData,
        timeout: 1000000000000000,
        onUploadProgress: back?(progressEvent) => {
            back(progressEvent);
        }:null
    })
}


export function getFile(url) {
    return http({
        url: url,
        method: 'get',
        responseType : 'blob'
    })
}



export function uuid() {
    if (typeof crypto === 'object') {
        if (typeof crypto.randomUUID === 'function') {
            return crypto.randomUUID();
        }
        if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
            const callback = (c) => {
                const num = Number(c);
                return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16);
            };
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, callback);
        }
    }
    let timestamp = new Date().getTime();
    let perforNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let random = Math.random() * 16;
        if (timestamp > 0) {
            random = (timestamp + random) % 16 | 0;
            timestamp = Math.floor(timestamp / 16);
        } else {
            random = (perforNow + random) % 16 | 0;
            perforNow = Math.floor(perforNow / 16);
        }
        return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
    });
}

export function formatSeconds(val) {
    let secondTime = parseInt(val);// 秒
    let minuteTime = 0;// 分
    let hourTime = 0;// 小时
    if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取余，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            //获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            //获取小时后取余的分，获取分钟除以60取余的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    let result;
    let hours = hourTime < 10 ? "0" + hourTime : hourTime;
    let minutes = minuteTime < 10 ? "0" + minuteTime : minuteTime;
    let seconds = secondTime < 10 ? "0" + secondTime : secondTime;
    if (hours == '00') {
        result = minutes + ":" + seconds;
    } else {
        result = hours + ":" + minutes + ":" + seconds;
    }
    return result;
}

export function formatDate(date, fmt) {
    if (typeof date == 'string') {
        return date;
    }
    if (fmt && fmt == 1) {
        fmt = "yyyy-MM-dd";
    }
    if (!fmt) fmt = "yyyy-MM-dd hh:mm:ss";

    if (!date || date == null) return null;
    let o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
    return fmt
}

export function getBlob(url,start,end) {
    let opt= {
        url: url,
        method: 'get',
        responseType: 'blob',
        headers:{}
    };
    opt.headers['Range']='bytes=' + start + '-' ;
    if(end){
        opt.headers['Range']='bytes=' + start + '-' +end;
    }
    return http(opt);
}

export function getArraybuffer(url,start,end) {
    let opt= {
        url: url,
        method: 'get',
        responseType: 'arraybuffer',
        headers:{}
    };
    opt.headers['Range']='bytes=' + start + '-' ;
    if(end){
        opt.headers['Range']='bytes=' + start + '-' +end;
    }
    return http(opt);
}


/****
 * 监听div宽高变化
 * @param div
 */
export function resizeObserverDiv(div,bak){
    const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
            const { width, height } = entry.contentRect;
            requestAnimationFrame(() => {
                // 改变尺寸的代码
                bak(width,height)
            });
        }
    });
    resizeObserver.observe(div);
}




/***
 * 加密保存
 *
 */
export function saveSessionStorage(key,data) {
    if(data){
        window.sessionStorage.setItem(key,Encrypt(JSON.stringify(data)))
    }else{
        window.sessionStorage.setItem(key,'')
    }

}

/***
 * 解密取值
 *
 */
export function getSessionStorage(key) {
    let str =window.sessionStorage.getItem(key);
    if(str){
        return  JSON.parse(Decrypt(str));
    }else{
        return  '';
    }

}

/***
 * 判断数组是否包含 obj 的key值，
 *
 */
export function contains(arr,obj,objKey,arrKey) {
   if(Array.isArray(arr)) {
       if (arr && arr.length > 0){
           for(let i=0 ;i< arr.length; i++){
               if(arrKey){
                   if(arr[i][arrKey]==obj[objKey]){
                       return true
                   }
               }else{
                   if(arr[i][objKey]==obj[objKey]){
                       return true
                   }
               }
           }
       }
   }
   return false
}

export function authTab(value){
    if(sessionStorage.getItem('accountId')=='sysadmin'){
        return true;
    }else{
        const authCodes = getSessionStorage(constants.CODES);
        if(authCodes.includes(value)){
            return true;
        }else{
            return false;
        }
    }
}